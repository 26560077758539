// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";
// $bootstrap-icons-font-src: url("bootstrap-icons/font/fonts/bootstrap-icons.woff2")
//     format("woff2"),
//   url("bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
// @import "bootstrap-icons/font/bootstrap-icons.scss";


@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

$primary: #b99470;

a {
  color: #b99470;
}

.nav-link {
  color: #000;
  &.active {
    color: $primary;
  }
}
.top-navbar {
  height: 3rem;
  border-bottom: $primary 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lh,
  .rh {
    display: flex;
  }
}

.icon-circle {
  width: 1.5rem;
  height: 1.5rem;
  background-color: $primary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
}

.icon-circle-xl {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.5rem;
}

.main-navbar {
  &.navbar {
    height: 5rem;
  }
  .navbar-brand {
    color: $primary;
    font-size: 24px;
    font-weight: 600;
  }
}

.section-wrapper {
  background-color: #f2f2f3;
}

.section-title-left:after,
.section-title-center:after {
  background: $primary;
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  margin: 15px auto;
}

.feature-icon {
  font-size: 1.5rem;
  color: $primary;
}

.footer-wrapper {
  background-color: #f2f2f3 !important;
  border-top: #dedede 1px solid;
}
.footer-icon {
  color: $primary;
  font-size: 1.2rem;
}

.footer-bottom {
  background-color: $primary;
  padding: 1rem;
  color: #fff;
}
